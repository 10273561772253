<template>
  <settings-layout>
    <template #actions>
      <b-button size="sm" variant="link" class="p-0 mr-1" @click="patchSetting">
        <feather-icon icon="UploadCloudIcon" size="16" class="align-middle text-body"/>
      </b-button>
    </template>

    <template #content>

<!--      <b-alert show variant="primary">
        <b-icon-info-circle class="mr-50"/>
        These setting effect both applications and students.
      </b-alert>-->

      <template v-if="setting">
        <!-- Support Center -->
        <b-row class="setting-row">
          <b-col cols="12" class="setting-col setting-header bg-setting-header">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title"><strong>Support Center</strong></div>
                <div class="setting-state">
                  <small>/support</small>
                </div>
              </div>
            </div>

          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Enabled</div>
                <div class="setting-state">
                  <b-checkbox v-model="setting.landing.enabled"
                              switch size="sm"
                              @change="patchSetting"/>
                </div>
              </div>
              <div class="setting-desc">This setting enables/disables the main support landing page.</div>
            </div>

          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Email Address</div>
                <div class="setting-state"></div>
              </div>
              <div class="setting-desc">The email address that appears on the the main support landing page.</div>
              <div class="setting-input">
                <b-input v-model="setting.landing.email" switch @change="patchSetting"></b-input>
              </div>
            </div>

          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Phone Number</div>
                <div class="setting-state"></div>
              </div>
              <div class="setting-desc">The phone number that appears on the the main support landing page.</div>
              <div class="setting-input">
                <b-input v-model="setting.landing.phone" switch @change="patchSetting"></b-input>
              </div>
            </div>

          </b-col>
        </b-row>

        <!-- Getting Started -->
        <b-row class="setting-row">
          <b-col cols="12" class="setting-col setting-header bg-setting-header">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title"><strong>Getting Started</strong></div>
                <div class="setting-state">
                  <small>/support/getting-started</small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Enabled</div>
                <div class="setting-state">
                  <b-checkbox v-model="setting.tours.enabled"
                              switch size="sm"
                              @change="patchSetting"/>
                </div>
              </div>
              <div class="setting-desc">This setting enables/disables the getting started page.</div>
            </div>
          </b-col>
        </b-row>

        <!-- Documentation -->
        <b-row class="setting-row">
          <b-col cols="12" class="setting-col setting-header bg-setting-header">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title"><strong>Documentation</strong></div>
              </div>
            </div>
            <div class="setting-state">
              <small>/support/docs</small>
            </div>
          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Enabled</div>
                <div class="setting-state">
                  <b-checkbox v-model="setting.documentation.enabled"
                              switch size="sm"
                              @change="patchSetting"/>
                </div>
              </div>
              <div class="setting-desc">This setting enables/disables the documentation pages.</div>
            </div>
          </b-col>
        </b-row>

        <!-- Tickets -->
        <b-row class="setting-row">
          <b-col cols="12" class="setting-col setting-header bg-setting-header">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title"><strong>Tickets</strong></div>
                <div class="setting-state">
                  <small>/support/tickets</small>
                </div>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="setting-col">
            <div class="setting-wrapper">
              <div class="setting-header">
                <div class="setting-title">Enabled</div>
                <div class="setting-state">
                  <b-checkbox v-model="setting.tickets.enabled"
                              switch size="sm"
                              @change="patchSetting"/>
                </div>
              </div>
              <div class="setting-desc">This setting enables/disables the documentation pages.</div>
            </div>
          </b-col>
        </b-row>
      </template>
    </template>

    <template #debug>
      <debug title="setting" collapsed class="mt-2">{{ setting }}</debug>
    </template>

  </settings-layout>
</template>


<script>
import { API, graphqlOperation} from 'aws-amplify';
import { getSetting } from '@/graphql/queries';
import { updateSetting } from '@/graphql/mutations';
import notify from '@/mixins/notify.mixin';
import vSelect from 'vue-select';
import SettingsLayout from '@/views/management/settings/SettingsLayout.vue';

export default {
  name: 'SettingsApplication',
  components: {
    SettingsLayout,
    vSelect
  },
  mixins: [ notify ],
  data() {
    return {
      key: 'support',
      setting: null,
      icon: 'fas fa-gear',
      options: {
        groups: {
          loading: false,
          items: []
        },
        users: {
          loading: false,
          items: []
        }
      },
      popperSettings: {
        placement: 'bottom'
      },
    }
  },
  async mounted() {
    await this.getSetting()
  },
  methods: {
    async getSetting() {
      const response = await API.graphql(graphqlOperation(getSetting, { key: this.key }));
      this.setting = JSON.parse(response.data?.getSetting?.value) || null
    },
    async patchSetting() {
      try {
        const settingInput = {
          key: this.key,
          value: JSON.stringify(this.setting)
        }
        const response = await API.graphql(graphqlOperation(updateSetting, { input: settingInput }));
        this.setting = JSON.parse(response.data?.updateSetting?.value) || null;
        this.notify({ title: 'Success', text: 'Setting was successfully updated', icon: this.icon, variant: 'success' });
      }
      catch(error) {
        this.notify({ title: 'Error', text: 'Setting failed to update', icon: this.icon, variant: 'danger'});
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/nyssma/settings.scss';
</style>
